<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="pricing_container">
			<div class="pricing_title">
				<h1>암호 스키마 요금 계산기</h1>
				<p>
					원하는 플랫폼을 선택하고 요금 계산기로 대략의 요금을 측정해 보세요
				</p>
			</div>
			<!--pricing_crypto_title end-->
			<div id="pricing_form">
				<div class="form_pricing_group">
					<label for="input-3">설치 컴퓨터</label>
					<input
						type="text"
						v-model="option"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>
			</div>
			<!--pricing_form-->
			<div class="form_pricing_group_button">
				<button @click="showResult">요금 결과</button>
			</div>
			<!--button-->

			<div id="pricing_result_container">
				<div class="title_content">
					<div class="form_result_title">
						<p>요금표시 <i class="fas fa-caret-down"></i></p>
					</div>
					<div class="form_result_content">
						<div class="form_result_box form_crypto_result_box">
							<label>설치 컴퓨터 : </label>
							<span>{{ this.result }}</span>
						</div>
					</div>
					<!--form_result_content end-->
				</div>

				<div class="form_result_pricing">
					<div class="form_result_box">
						<label>총 요금 예상 비용</label>
						<span>{{ this.pricing }}</span>
					</div>
					<div class="pricing_button_box">
						<button class="file">파일 다운로드</button>
						<button class="pay">결제하기</button>
					</div>
				</div>
				<!--form_result_pricing-->
			</div>
			<!--pricing_result_container end-->
		</div>
		<!--pricing_crypto_container-->
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
export default {
	components: { MainHeader, Footer, MenuIntro },
	data() {
		return {
			menuKr: '암호 스키마 요금',
			menuEn: 'pricing',
			option: '',
			result: '',
			pricing: '',
		};
	},
	methods: {
		showResult() {
			{
				this.result = this.option;
				this.pricing = this.option * 599000;
			}
		},
	},
};
</script>

<style></style>
